import React, { useEffect, useState } from 'react'
import { GetStaticProps } from 'next';
//constext
import Landingpages_Mobile from '@/components/landingpageComponents/landingpages_Mobile/landingpages_Mobile';
import Landingpage_desktop from '@/components/landingpageComponents/landingpage_Desktop/landingpage_desktop';
import Seo from '@/components/common/seo/Seo';


const Index = () => {

  const [isMobileView, setIsMobileView] = useState(true);

  useEffect(() => {
    checkViewport();
    window.addEventListener('resize', checkViewport);
    return () => {
      window.removeEventListener('resize', checkViewport);
    };
  }, []);

  const checkViewport = () => {
    const isMobileView = window.matchMedia('(max-width: 767px)').matches;
    setIsMobileView(isMobileView);
  };

  return (
    <div>
      <Seo />
      {isMobileView ? (
        <Landingpages_Mobile />
      ) : (
        <Landingpage_desktop />
      )}
    </div>)
}

export default Index

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {},
  };
};
