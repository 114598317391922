import React from 'react';
//styles
import styles from './newfriends.module.scss'
import classNames from 'classnames';
import "animate.css";
import {motion} from 'framer-motion';
//images
import Image from 'next/image';

const Newfriends = () => {
    return (
        <>
            <motion.div 
             animate={{ y: 0, opacity: 0 }}
             transition={{ type: 'spring', damping: 34, stiffness: 230, mass: 2, delay:0.15}}
             whileInView={{ y: 0, opacity:1 }}
             viewport={{ once: true }} 
              className={classNames(styles.container_secondpage, styles["mb-20"],styles["mt-96"])}>
                <div className={classNames(styles.game_always)}>Make new  <span >{" "} friends </span></div>
                <div className={classNames(styles.ready_for_you)}>on court
                   </div>
                <div className={classNames(styles.ready_content, styles['mt-20'])}>
                Meet interesting players who share your 
                </div>
                <div className={classNames(styles.ready_content)}>
                love for the game and grow your sports 
                </div>
                <div className={classNames(styles.ready_content, styles['mb-48'])}>
                circle!
                </div>
            </motion.div>
            <motion.div  animate={{ y: 0, opacity: 0 }}
                          transition={{ type: 'spring', damping: 34, stiffness: 230, mass: 2, delay:0.15}}
                          whileInView={{ y: 0, opacity:1 }}
                          viewport={{ once: true }} 
                          className={classNames(styles.container_secondpage_image)}>
                <Image src={'/landingimages/purpleboys.webp'} alt="" height={338} width={324} 
                className='animate__animated animate__fadeIn'/>
            </motion.div>
        </>
    )
}

export default Newfriends;